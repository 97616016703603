import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCard from "@components/services/service-card";
import { SERVICE_DATA } from "@data";

import { useTranslation } from "react-i18next";
import "../../languages/i18n.js";
import { FaCalendarAlt } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { MdRssFeed } from "react-icons/md";
import { TiWeatherPartlySunny } from "react-icons/ti";

const ServicesHome = () => {
  const { t, i18n } = useTranslation();

  const { sectionTitle, posts } = SERVICE_DATA;
  return (
    <section id="features" className="service-one">
      <Container>
        <div className="block-title text-center">
          <p>{t("options.carpin")}</p>
          <h3>{t("options.title")}</h3>
        </div>
        <Row>
          <Col xl={3} lg={3} md={6} key={`service-card-key-0`}>
            <div className="service-one__single">
              <div className={`service-icon icon-`}>
                <div className={`icon-box icon-box-`}>
                  <i>
                    <MdRssFeed />
                  </i>
                </div>
              </div>
              <div className="text">
                <h3>{t("description.bandos")}</h3>
                <p>{t("description.bandosSubtitle")}</p>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={3} md={6} key={`service-card-key-1`}>
            <div className="service-one__single">
              <div className={`service-icon icon-bg-2`}>
                <div className={`icon-box icon-box-bg-2`}>
                  <i>
                    <FaCalendarAlt />
                  </i>
                </div>
              </div>
              <div className="text">
                <h3>{t("description.events")}</h3>
                <p>{t("description.eventsSubtitle")}</p>
              </div>
            </div>
          </Col>

          <Col xl={3} lg={3} md={6} key={`service-card-key-2`}>
            <div className="service-one__single">
              <div className={`service-icon icon-bg-3`}>
                <div className={`icon-box icon-box-bg-3`}>
                  <i>
                    <FiAlertTriangle />
                  </i>
                </div>
              </div>
              <div className="text">
                <h3>{t("description.incidences")}</h3>
                <p>{t("description.incidencesSubtitle")}</p>
              </div>
            </div>
          </Col>
          <Col xl={3} lg={3} md={6} key={`service-card-key-3`}>
            <div className="service-one__single">
              <div className={`service-icon icon-bg-4`}>
                <div className={`icon-box icon-box-bg-4`}>
                  <i>
                    <TiWeatherPartlySunny />
                  </i>
                </div>
              </div>
              <div className="text">
                <h3>{t("description.weather")}</h3>
                <p>{t("description.weatherSubtitle")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServicesHome;
