import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

import { useTranslation } from "react-i18next";
import "../../languages/i18n.js";

const NavLinks = () => {
  const { t, i18n } = useTranslation();

  const handleDropdownStatus = e => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <ScrollLink
          activeClass="current"
          to=""
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {
            i18n.changeLanguage("gl");
            localStorage.setItem("language", "gl");
          }}
        >
          Galego
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to=""
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => {
            i18n.changeLanguage("es");
            localStorage.setItem("language", "es");
          }}
        >
          Castellano
        </ScrollLink>
      </li>
    </ul>
  );
};

export default NavLinks;
