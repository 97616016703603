import { I18nextProvider } from "react-i18next";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_ES } from "./es.js";
import { TRANSLATIONS_GAL } from "./gal.js";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    es: {
      translation: TRANSLATIONS_ES,
    },
    gl: {
      translation: TRANSLATIONS_GAL,
    },
  },
});
const ISSERVER = typeof window === "undefined";
i18n.changeLanguage("gl");

if (!ISSERVER) {
  if (
    !localStorage.getItem("language") ||
    (localStorage.getItem("language") != "es" &&
      localStorage.getItem("language") != "gl")
  ) {
    i18n.changeLanguage("gl");
  } else {
    i18n.changeLanguage(localStorage.getItem("language"));
  }
}
