import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

import { useTranslation } from "react-i18next";
import "../languages/i18n.js";
import { FaRegNewspaper, FaRegComments, FaCalendarAlt } from "react-icons/fa";

const Banner = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="banner-one" id="home">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <img src={bgShape2} alt="Banner-Shape-2" className="banner-shape-2" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={6} lg={6}>
            <div className="banner-one__content">
              <h3>
                Comunicación
                <br />
                Accesible
                <br />
                Rural
              </h3>
              <p>
                {t("home.title1")} <br />
                {t("home.title2")}
              </p>
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className="banner-img wow fadeInUp" data-wow-duration="1500ms">
              <img src={bannerShape1} className="banner-image__curvs" alt="" />
              <div
                className="banner-bg"
                style={{ backgroundImage: `url(${bannerShape2})` }}
              ></div>
              <img src={image} alt="Banner-img" />
              <div className="banner-icon-1">
                <FaRegNewspaper />
              </div>
              <div className="banner-icon-2">
                <FaRegComments />
              </div>
              <div className="banner-icon-3">
                <FaCalendarAlt />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
