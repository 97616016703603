export const TRANSLATIONS_ES = {
  home: {
    title1:
      "CAR es la herramienta de comunicación bidireccional entre tú y tu Concello. ",
    title2:
      "Regístrate con tu CARPIN y accede a los bandos digitalizados o escúchalos con el motor de texto a voz integrado en la app, explora los eventos del Concello, reporta incidencias directamente a la administración o consulta el pronóstico meteorológico.",
    download: "Descarga a app",
  },
  options: {
    carpin: "Consigue tu CarPin presencialmente en tu Concello",
    title: "Censándote en el Concello podrás:",
  },
  description: {
    bandos: "Acceder a la versión digital de los bandos",
    bandosSubtitle: "Lee o escucha los bandos del Concello desde tu móbil",
    incidences: "Reportar incidencias",
    incidencesSubtitle:
      "Mantén contacto con el Concello y reporta posibles problemas",
    events: "Estar al día",
    eventsSubtitle: "No te pierdas ningún evento en tú área",
    weather: "Consultar el tiempo",
    weatherSubtitle: "Accede al pronóstico meteorológico de los próximos días",
  },
  download: {
    subtitle: "Descarga gratis la app",
    title: "Disponible en",
  },
};
