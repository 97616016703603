import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import shape1 from "@images/shapes/cta-1-shape-1.png";
import shape2 from "@images/shapes/cta-1-shape-2.png";
import appStore from "@images/appStore.png";
import playStore from "@images/playStore.png";
import shape3 from "@images/shapes/cta-1-shape-3.png";
import moc from "@images/resources/cat-1-moc-1.png";

import { CALL_TO_ACTION_DATA } from "@data";

import { useTranslation } from "react-i18next";
import "../languages/i18n.js";

const CallToAction = () => {
  const { t, i18n } = useTranslation();

  const { sectionTitle, paragraph, posts, button } = CALL_TO_ACTION_DATA;
  return (
    <section className="cta-one" id="download">
      <img src={shape1} className="cta-one__bg-shape-1" alt="" />
      <img src={shape2} className="cta-one__bg-shape-2" alt="" />
      <Container>
        <Row>
          <Col xl={12} lg={12}>
            <div className="cat_one_content">
              <div className="block-title block-title-fix">
                <p>{t("download.subtitle")}</p>
                <h3>{t("download.title")}</h3>
              </div>
              <div className="cat_one_icon-wrap">
                {/* APPLE */}
                <Col xl={6} lg={6}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.vpfscandinavia.car"
                    target="blank"
                  >
                    <img
                      src={playStore}
                      width="200"
                      className="imageStore"
                      alt=""
                    />
                  </a>
                </Col>
                {/* google */}
                <Col xl={6} lg={6}>
                  <a
                    href="https://apps.apple.com/us/app/car/id1634010752"
                    target="blank"
                  >
                    <img
                      src={appStore}
                      width="200"
                      className="imageStore"
                      alt=""
                    />
                  </a>
                </Col>
              </div>

              <div className="cta-one__text"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CallToAction;
