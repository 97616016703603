export const TRANSLATIONS_GAL = {
  home: {
    title1:
      "CAR é a ferramenta de comunicación bidireccional entre ti e o teu Concello. ",
    title2:
      "Rexístrate co teu CARPIN e accede aos bandos dixitalizados ou escóitaos co motor de texto a voz integrado na app, explora os eventos do Concello, reporta incidencias directamente á administración ou consulta o pronóstico meteorolóxico.",
    download: "Descarga a app",
  },
  options: {
    carpin: "Consegue o teu CarPin no teu concello",
    title: "Censándote no Concello poderás:",
  },
  description: {
    bandos: "Acceder á versión dixital dos bandos",
    bandosSubtitle: "Lee ou escoita os bandos do Concello desde o teu móbil",
    incidences: "Reportar incidencias",
    incidencesSubtitle:
      "Mantén contacto co Concello e reporta posibles problemas",
    events: "Estar ao día do que ocorre",
    eventsSubtitle: "Non te perdas ningún evento na túa área",
    weather: "Consultar o tempo",
    weatherSubtitle: "Accede ó pronóstico meteorolóxico dos próximos días",
  },
  download: {
    subtitle: "Descarga gratis a app",
    title: "Dispoñible en",
  },
};
